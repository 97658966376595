import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide46/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide46/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide46/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide46/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide46/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Crea un diagrama de flujo de atención al cliente en simples pasos
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Mejorar el servicio al cliente se vuelve fundamental para la mayoría de
      las empresas, y una estrategia clave para lograrlo es el desarrollo de un
      flujo de trabajo eficiente. Un recurso valioso en este proceso es el
      diagrama de flujo de servicio al cliente.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Este documento presenta de manera gráfica las diversas actividades de los
    agentes, proporcionando una visión clara de los procesos existentes y de
    aquellos que aún no se han visualizado.
    <br />
    <br />
    Este enfoque no solo optimiza los procesos de servicio existentes, sino que
    también permite a los gerentes identificar cuellos de botella o
    ineficiencias dentro de los procesos de atención al cliente.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Identificación tus puntos de contacto.",
      },
      {
        key: 2,
        text: " Creación del proceso de trabajo.",
      },
      {
        key: 3,
        text: "Cómo eliminar Problemas Potenciales.",
      },
      {
        key: 4,
        text: "Revisión del diagrama de flujo.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
